import { connect } from 'react-redux'
import CompanySelectionView from '../components/CompanySelectionView'
import { getUserCompanyList } from './selectors/user-selectors'
import { handleCompanySelect } from '../modules/redirects'

const mapStateToProps = state => ({
  username: `${state.user.firstName} ${state.user.lastName}`,
  companies: getUserCompanyList(state)
})

const mapDispatchToProps = {
  handleCompanySelect
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanySelectionView)
