import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField, InputAdornment, Button, makeStyles, CircularProgress } from '@material-ui/core'
import UsernameIcon from '@material-ui/icons/Person'
import { useTranslation } from 'react-i18next'
import { RenderGate } from '@heeros/react-sdk'

const useStyles = makeStyles(theme => ({
  formContainer: {
    paddingTop: theme.spacing(2)
  },
  submitContainer: {
    position: 'relative'
  },
  submitProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}))

const NoText = () => (
  <span>&nbsp;</span>
)

const MfaForm = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [inputValues, setInputValues] = useState({ usercode: '' })

  const handleInputChange = key => event => setInputValues({ ...inputValues, [key]: event.target.value })

  return (
    <Grid container direction='column' className={classes.formContainer} spacing={2}>
      <Grid item>
        <TextField
          data-test='usercode'
          name='usercode'
          autoComplete='new-password'
          value={inputValues.usercode}
          onChange={handleInputChange('usercode')}
          label={t('MfaChallengeView.usercodeLabel')}
          placeholder={t('MfaChallengeView.usercodeLabel')}
          fullWidth
          variant='outlined'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <UsernameIcon />
              </InputAdornment>
            )
          }}
          onKeyPress={event => {
            if (event.key === 'Enter') props.handleMfaSubmit(inputValues)
          }}
        />
      </Grid>
      <Grid item className={classes.submitContainer}>
        <Button
          data-test='loginButton'
          variant='contained'
          color='primary'
          disabled={props.isSubmitting}
          fullWidth
          onClick={() => props.handleMfaSubmit(inputValues)}
        >
          {props.isSubmitting ? <NoText /> : t('MfaChallengeView.loginButton')}
        </Button>
        <RenderGate shouldRender={props.isSubmitting}>
          <CircularProgress size={24} className={classes.submitProgress} />
        </RenderGate>
      </Grid>
    </Grid>
  )
}

MfaForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  handleLoginSubmit: PropTypes.func.isRequired
}

export default MfaForm
