import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField, InputAdornment, Button, makeStyles, CircularProgress, Typography, Fade } from '@material-ui/core'
import UsernameIcon from '@material-ui/icons/Person'
import { useTranslation } from 'react-i18next'
import { RenderGate } from '@heeros/react-sdk'
import AlertBox from '../LoginView/AlertBox'

const useStyles = makeStyles(theme => ({
  formContainer: {
    paddingTop: `${theme.spacing(2)}px !important`
  },
  infoBoxContainer: {
    padding: theme.spacing(3, 0)
  },
  submitContainer: {
    position: 'relative'
  },
  submitProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  rootContainer: {
    minHeight: '300px'
  }
}))

const NoText = () => (
  <span>&nbsp;</span>
)

const ForgotPasswordView = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [username, setUsername] = useState(props.username)
  return (
    <Fade in>
      <Grid container direction='column' className={classes.rootContainer}>
        <Typography variant='h4'>{t('forgotPasswordView.titleText')}</Typography>
        <Grid item className={classes.infoBoxContainer}>
          <AlertBox
            severity='info'
            elevation={1}
            message={t('forgotPasswordView.infoBox')}
          />
        </Grid>
        <Grid item container direction='column' spacing={2}>
          <Grid item>
            <TextField
              data-test='reset-username'
              name='reset-username'
              autoComplete='username'
              autoFocus
              value={username}
              onChange={e => setUsername(e.target.value)}
              label={t('loginView.usernameLabel')}
              placeholder={t('loginView.usernameLabel')}
              fullWidth
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <UsernameIcon />
                  </InputAdornment>
                )
              }}
              onKeyPress={event => {
                if (event.key === 'Enter') props.handleResetPasswordSubmit(username)
              }}
            />
          </Grid>
          <Grid item className={classes.submitContainer}>
            <Button
              data-test='loginButton'
              variant='contained'
              color='primary'
              disabled={props.isSubmitting}
              fullWidth
              onClick={() => props.handleResetPasswordSubmit(username)}
            >
              {props.isSubmitting ? <NoText /> : t('forgotPasswordView.submitButton')}
            </Button>
            <RenderGate shouldRender={props.isSubmitting}>
              <CircularProgress size={24} className={classes.submitProgress} />
            </RenderGate>
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  )
}

ForgotPasswordView.propTypes = {
  username: PropTypes.string,
  handleResetPasswordSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool
}

ForgotPasswordView.defaultProps = {
  isSubmitting: false
}

export default ForgotPasswordView
