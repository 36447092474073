import { useMemo } from 'react'
import PasswordValidator from 'password-validator'

const MIN_PW_LENGTH = 8
const MAX_PW_LENGTH = 98

const OnlyAllowedCharacters = /^[a-zA-Z0-9[\]/\\^$*.{}()?"!@#%&,><':;|_~`]*$/

const passwordSchema = new PasswordValidator()
  .has(OnlyAllowedCharacters)
  .is().min(MIN_PW_LENGTH)
  .is().max(MAX_PW_LENGTH)
  .has().uppercase()
  .has().lowercase()
  .has().digits()
  .has().symbols()

export const useValidator = (password, confirmPassword) => {
  const validationErrors = useMemo(() => {
    const errors = new Set()
    if (password === '') {
      errors.add('empty')
      return errors
    }
    if (password !== confirmPassword) {
      errors.add('mismatch')
    }
    const validationErrors = passwordSchema.validate(password, { list: true })
    for (const err of validationErrors) {
      errors.add(err)
    }
    return errors
  }, [password, confirmPassword])

  const hasErrors = validationErrors.size > 0

  return [validationErrors, hasErrors]
}
