import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { createHashHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'

import createRootReducer from './reducers'
import { initStore } from './init-store'
import { i18nMiddleware } from '../middlewares/i18n-middleware'

export const history = createHashHistory({
  hashType: 'slash'
})

const persistConfig = {
  key: 'react-template',
  storage,
  whitelist: []
}

export default () => {
  const middlewares = [
    thunk,
    routerMiddleware(history),
    i18nMiddleware
  ]

  const enhancers = []
  let composeEnhancers = compose

  if (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      actionsBlacklist: [
        '@@redux-form/REGISTER_FIELD',
        '@@redux-form/UNREGISTER_FIELD'
      ]
    })
  }

  const persistedReducer = persistReducer(persistConfig, createRootReducer(history))
  const store = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(...middlewares),
      ...enhancers
    )
  )

  persistStore(store)
  initStore(store)
  return store
}
