import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import MainLayout from '../../layouts/MainLayout'

const RouteWithLayout = ({ layout, component, ...props }) => {
  const Layout = layout || MainLayout
  return (
    <Layout>
      <Route component={component} {...props} />
    </Layout>
  )
}

RouteWithLayout.propTypes = {
  component: PropTypes.elementType.isRequired,
  layout: PropTypes.elementType
}

export default RouteWithLayout
