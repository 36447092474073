import { connect } from 'react-redux'
import MfaChallengeView from '../components/MfaChallengeView'
import { handleMfaSubmit } from '../modules/login'
import { getUserUnauthorized } from './selectors/user-selectors'

const mapStateToProps = state => ({
  ...state.login,
  userUnauthorized: getUserUnauthorized(state)
})

const mapDispatchToProps = {
  handleMfaSubmit
}

export default connect(mapStateToProps, mapDispatchToProps)(MfaChallengeView)
