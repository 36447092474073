import { createApiClient } from '@heeros/client-sdk'
import { replace } from 'connected-react-router'

import { createReducer } from '../store/create-reducer'
import { setAppLoadingState } from './app'
import { setLocaleFromBrowser } from './user'

const loginApi = createApiClient('login-v1')

const SET_SUBMIT_STATUS = 'SET_SUBMIT_STATUS'
const SET_RESET_REQUEST_ID = 'SET_RESET_REQUEST_ID '

const redirectToLogin = (loginServer) => {
  window.location.search = `resetCompleted&server=${loginServer}`
}

const setSubmitStatus = (isSubmitting, errorMessage = null) => ({
  type: SET_SUBMIT_STATUS,
  payload: { isSubmitting, errorMessage }
})

const setResetRequestId = resetRequestId => ({
  type: SET_RESET_REQUEST_ID,
  payload: { resetRequestId }
})

export const handleResetRequestQsp = (resetRequestId) =>
  (dispatch, getState) => {
    dispatch(setLocaleFromBrowser())
    dispatch(setResetRequestId(resetRequestId))
    dispatch(setAppLoadingState(false))
    dispatch(replace('/reset-password'))
  }

export const handlePasswordResetSubmit = (resetCode, newPassword) =>
  async (dispatch, getState) => {
    const resetRequestId = getState().reset.resetRequestId
    if (!newPassword || !resetRequestId || !resetCode) {
      return
    }
    dispatch(setSubmitStatus(true))
    try {
      const response = await loginApi.post(
        `/recoveries/${resetRequestId}`,
        JSON.stringify({
          code: resetCode,
          password: newPassword
        })
      )
      dispatch(setSubmitStatus(false))
      redirectToLogin(response.loginServer)
    } catch (err) {
      dispatch(setSubmitStatus(false, 'resetPasswordView.submitError'))
    }
  }

const initialState = {
  requestStatus: false,
  isSubmitting: false,
  errorMessage: null,
  resetRequestId: null
}

const ACTION_HANDLERS = {
  [SET_SUBMIT_STATUS]: (state, action) => ({
    ...state,
    isSubmitting: action.payload.isSubmitting,
    errorMessage: action.payload.errorMessage
  }),
  [SET_RESET_REQUEST_ID]: (state, action) => ({
    ...state,
    resetRequestId: action.payload.resetRequestId
  })
}

export default createReducer({
  handlers: ACTION_HANDLERS,
  initialState
})
