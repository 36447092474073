import { createReducer } from '../store/create-reducer'

export const SHOW_PROGRESS_OVERLAY = 'SHOW_PROGRESS_OVERLAY'
export const HIDE_PROGRESS_OVERLAY = 'HIDE_PROGRESS_OVERLAY'

export const showProgressOverlay = () => ({
  type: SHOW_PROGRESS_OVERLAY
})

export const hideProgressOverlay = () => ({
  type: HIDE_PROGRESS_OVERLAY
})

const ACTION_HANDLERS = {
  [SHOW_PROGRESS_OVERLAY]: (state, action) => ({
    ...state,
    isOpen: true
  }),
  [HIDE_PROGRESS_OVERLAY]: (state) => ({
    ...state,
    isOpen: false
  })
}

const initialState = {
  isOpen: false
}

export default createReducer({
  handlers: ACTION_HANDLERS,
  initialState
})
