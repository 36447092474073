import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import app from '../modules/app'
import user from '../modules/user'
import progressIndicator from '../modules/progress-indicator'
import login from '../modules/login'
import reset from '../modules/reset'

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  app,
  user,
  progressIndicator,
  login,
  reset
})

export default rootReducer
