import { Auth, Amplify } from 'aws-amplify'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'

const getEnvironmentFromHostname = () => {
  var host = window.location.hostname
  if (host.includes('cloud')) return 'prod'
  if (host.includes('pilot')) return 'pilot'
  if (host.includes('test')) return 'test'
  return 'dev'
}

const configure = () => {
  const env = getEnvironmentFromHostname().toUpperCase()
  const configuration = {
    userPoolWebClientId: process.env['REACT_APP_CLIENT_ID_' + env],
    userPoolId: process.env['REACT_APP_USER_POOL_ID_' + env]
  }
  Amplify.configure({ Auth: configuration })

  return configuration
}
const cognitoConfig = configure()

const buildUserSession = auth => {
  const AccessToken = new AmazonCognitoIdentity.CognitoAccessToken({
    AccessToken: auth.accessToken
  })

  const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
    IdToken: auth.idToken
  })

  const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
    RefreshToken: auth.refreshToken
  })

  const sessionData = {
    IdToken,
    AccessToken,
    RefreshToken
  }
  return new AmazonCognitoIdentity.CognitoUserSession(sessionData)
}
const buildUserPool = () => {
  const poolData = {
    UserPoolId: cognitoConfig.userPoolId,
    ClientId: cognitoConfig.userPoolWebClientId
  }
  return new AmazonCognitoIdentity.CognitoUserPool(poolData)
}
const buildUser = (user, userPool) => {
  var userData = {
    Username: user,
    Pool: userPool
  }

  return new AmazonCognitoIdentity.CognitoUser(userData)
}
const setSession = auth => {
  try {
    const session = buildUserSession(auth)
    const userPool = buildUserPool()
    const username = session.accessToken.payload.username
    const user = buildUser(username, userPool)

    user.setSignInUserSession(session)
    return Auth.currentAuthenticatedUser()
  } catch (e) {
    console.error('Unable to set cognito session', e)
  }
}
const setDeviceRemembering = async (remember) => {
  try {
    await Auth.currentSession()
    if (remember) {
      await Auth.rememberDevice()
    } else {
      await Auth.forgetDevice()
    }
  } catch (e) {
    console.error('Unable to set device remembering', e)
  }
}
const srpAuth = async (username, password) => {
  const user = await Auth.signIn(username, password)
  return user
}

export { setSession, setDeviceRemembering, srpAuth }
