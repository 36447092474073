import { createSelector } from 'reselect'

const ValidLoginServers = Object.freeze([
  'poseidon',
  'artemis',
  'zeus',
  'zeus2',
  'zeus3',
  'zeus4',
  'zeus5',
  'zeus6',
  'zeus7',
  'zeus8',
  'zeus9',
  'zeus10',
  'zeus12',
  'zeus13',
  'zeus14'
])

const getRedirectUrl = state => state.login.redirectUrl

export const getServerFromQuery = state => state.login.loginServer

export const getPasswordResetCompleted = state => state.login.resetCompleted

export const getMfaSession = state => state.login.mfaSession

export const getMfaUsername = state => state.login.mfaUsername

export const getMfaChallenge = state => state.login.mfaChallenge

export const getServerFromRedirectUrl = createSelector(getRedirectUrl, redirectUrl => {
  try {
    const server = new URL(redirectUrl).host.split('.')[0]
    return ValidLoginServers.includes(server)
      ? server
      : null
  } catch (err) {
    return null
  }
})
