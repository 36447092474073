import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  TextField,
  Grid,
  Chip,
  makeStyles
} from '@material-ui/core'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { useTranslation } from 'react-i18next'

import CompanyOptions from './CompanyOptions'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(-15)
    }
  },
  nameTag: {
    borderRadius: 0
  },
  listbox: {
    maxHeight: '20vh',
    [theme.breakpoints.down('lg')]: {
      maxHeight: '25vh'
    }
  }
}))

const CompanySelectionView = props => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [isOpen, setOpen] = useState(true)

  return (
    <Grid container direction='column' spacing={2} className={classes.root}>
      <Grid item>
        <Chip
          label={t('companySelectionView.greetingsText', { user: props.username })}
          className={classes.nameTag}
        />
      </Grid>
      <Grid item>
        <Typography variant='h4'>{t('companySelectionView.titleText')}</Typography>
      </Grid>
      <Grid item>
        <Autocomplete
          classes={{ listbox: classes.listbox }}
          PopperComponent={CompanyOptions}
          options={props.companies}
          groupBy={(option) => t(`companySelectionView.categories.${option.type}`)}
          getOptionLabel={(option) => option.name}
          filterOptions={createFilterOptions({
            stringify: option => `${option.name} ${option.id}`
          })}
          openOnFocus
          autoHighlight
          fullWidth
          open={isOpen}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          openText={t('material-ui:MuiAutocomplete.openText')}
          closeText={t('material-ui:MuiAutocomplete.closeText')}
          onChange={(event, option) => props.handleCompanySelect(option)}
          renderInput={(params) =>
            <TextField
              {...params}
              placeholder={t('companySelectionView.selectInputPlaceholder')}
              variant='outlined'
              fullWidth
              autoFocus
            />}
        />
      </Grid>
    </Grid>
  )
}

CompanySelectionView.propTypes = {
  companies: PropTypes.array.isRequired
}

export default CompanySelectionView
