import React from 'react'
import { Popper } from '@material-ui/core'

const CompanyOptions = props => (
  <Popper
    {...props}
    placement='bottom'
    modifiers={{
      flip: {
        enabled: false
      }
    }}
  />
)

export default CompanyOptions
