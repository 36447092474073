import React, { useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Dialog, DialogContent, DialogTitle, Grid, Grow, Link, makeStyles, Typography } from '@material-ui/core'
import CheckIconPlain from '@material-ui/icons/Check'
import { Alert } from '@material-ui/lab'

const PasswordGuidelines = Object.freeze([
  { key: 'has', label: 'invalidCharacters' },
  { key: 'min', label: 'minimum' },
  { key: 'max', label: 'maximum' },
  { key: 'uppercase', label: 'uppercase' },
  { key: 'lowercase', label: 'lowercase' },
  { key: 'digits', label: 'digits' },
  { key: 'symbols', label: 'symbols' }
])

const useStyles = makeStyles(theme => ({
  passwordRuleContainer: {
    marginBottom: theme.spacing(0.5)
  },
  passwordRule: {
    display: 'flex',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest
    })
  },
  passwordRuleCheck: {
    marginRight: theme.spacing(1)
  },
  passwordRuleLabel: {
    marginLeft: 0,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shortest
    })
  },
  dim: {
    marginLeft: `calc(-20px - ${theme.spacing(1)}px)`,
    color: theme.palette.text.hint
  },
  infoBoxContainer: {
    paddingTop: theme.spacing(4)
  },
  infoBox: {
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest
    }),
    '& ul': {
      marginTop: theme.spacing(1),
      padding: theme.spacing(0, 0, 0, 4)
    },
    '& li': {
      ...theme.typography.body2,
      lineHeight: 1.55
    }
  },
  infoBoxSubHeader: {
    margin: theme.spacing(0, 0, 1, 0)
  },
  hintDialogContent: {
    minWidth: '300px'
  },
  symbolsContainer: {
    display: 'flex',
    marginBottom: theme.spacing(2)
  },
  symbols: {
    margin: 0,
    marginLeft: theme.spacing(0.5),
    display: 'inline-block'
  }
}))

const Check = (props) => {
  const { checked, label, onHintClick } = props

  const classes = useStyles()
  const { t } = useTranslation()
  const symbolHint = useMemo(() => {
    if (label === 'invalidCharacters') {
      return (
        <Link
          component='button'
          onClick={onHintClick}
          style={{ verticalAlign: 'top' }}
        >
          [?]
        </Link>
      )
    }
    return null
  }, [label, onHintClick])

  return (
    <div className={classes.passwordRuleContainer}>
      <Typography variant='body2' className={classes.passwordRule}>
        <Grow in={checked}>
          <CheckIconPlain
            fontSize='small'
            className={classes.passwordRuleCheck}
          />
        </Grow>
        <span className={classNames(classes.passwordRuleLabel, !checked && classes.dim)}>
          {t(`resetPasswordView.validation.${label}`)} {symbolHint}
        </span>
      </Typography>
    </div>
  )
}

const PasswordInfoBox = (props) => {
  const { errors } = props

  const [isSymbolDialogOpen, setSymbolDialogOpen] = useState(false)
  const classes = useStyles()
  const { t } = useTranslation()

  const isEmpty = errors.has('empty')
  const hasMismatch = errors.has('mismatch')
  const done = errors.size === 0 || (hasMismatch && errors.size === 1)

  return (
    <>
      <Dialog
        open={isSymbolDialogOpen}
        onClose={() => setSymbolDialogOpen(false)}
      >
        <DialogTitle>
          {t('resetPasswordView.hintDialog.allowedCharacters')}
        </DialogTitle>
        <DialogContent
          className={classes.hintDialogContent}
        >
          <Typography>
            <strong>
              {t('resetPasswordView.hintDialog.letters')}:&nbsp;
            </strong>
            a-z / A-Z
          </Typography>
          <Typography>
            <strong>
              {t('resetPasswordView.hintDialog.numbers')}:&nbsp;
            </strong>
            0-9
          </Typography>
          <Typography className={classes.symbolsContainer}>
            <strong>
              {t('resetPasswordView.hintDialog.symbols')}:&nbsp;
            </strong>
            <pre className={classes.symbols}>{'^ $ * . [ ]\n{ } ( ) ? "\n! @ # % & /\n\\ , > < \' :\n; | _ ~ `'}</pre>
          </Typography>
        </DialogContent>
      </Dialog>
      <Grid item className={classes.infoBoxContainer}>
        <Alert severity={done ? 'success' : 'info'} elevation={1} className={classes.infoBox}>
          <Typography variant='body1' className={classes.infoBoxSubHeader}>
            {t('resetPasswordView.validationTitle')}
          </Typography>
          {PasswordGuidelines.map(rule => (
            <Check
              key={rule.key}
              checked={!isEmpty && !errors.has(rule.key)}
              label={rule.label}
              onHintClick={() => setSymbolDialogOpen(true)}
            />
          ))}
        </Alert>
      </Grid>
    </>
  )
}

export default PasswordInfoBox
