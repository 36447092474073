import { setAppLoadingState, setIsUnauthorized } from '../modules/app'
import { fetchCurrentUser, handleUnauthenticatedUser, handleAuthenticatedUser } from '../modules/user'
import { setLoginServer, setRedirectUrl, setResetCompleted } from '../modules/login'
import { RouteName } from '../routes'
import { handleResetRequestQsp } from '../modules/reset'
import { getQueryParameter } from '../utils/qsp'

export const initStore = async ({ dispatch, getState }) => {
  const resetRequestId = getQueryParameter('resetRequestId')
  if (resetRequestId) {
    dispatch(handleResetRequestQsp(resetRequestId))
    return
  }

  let user

  try {
    user = await dispatch(fetchCurrentUser())
  } catch (error) {}

  if (user && window.location.href.includes(RouteName.UNAUTHORIZED)) {
    dispatch(setIsUnauthorized(true))
  }

  const redirectUrl = getQueryParameter('redirectTo') || getQueryParameter('RedirectTo')
  if (redirectUrl) {
    dispatch(setRedirectUrl(redirectUrl))
  }

  const loginServer = getQueryParameter('server')
  if (loginServer) {
    dispatch(setLoginServer(loginServer))
  }

  const resetCompleted = getQueryParameter('resetCompleted') !== null
  if (resetCompleted) {
    dispatch(setResetCompleted(true))
  }

  // Loading state must be set before handling the (navigation) flow, otherwise router won't work
  dispatch(setAppLoadingState(false))
  if (user) dispatch(handleAuthenticatedUser())
  else dispatch(handleUnauthenticatedUser())
}
