import { createSelector } from 'reselect'
import { getAccountingCompanyRedirectUrl } from '../../utils/app-settings'
import _ from 'lodash'

const getUser = state => state.user
const getUserAuthorities = state => state.user.authorities
export const getUserUnauthorized = state => state.app.authenticatedUserUnauthorized

const filterAccountingCompanies = accountingCompanies => accountingCompanies.filter(x => x.accountingCompany && typeof x.endCustomer === 'undefined')
const filterAdministrations = accountingCompanies => accountingCompanies.filter(x => x.accountingCompany && x.endCustomer)
const getUniqueItems = (items, propertyName) => _.uniqBy(items, propertyName)

const filterAuthorities = (authorities, customerType) => {
  const dominoAuthorities = authorities.filter(x => x['@type'].toLowerCase() === 'domino')
  if (customerType === 'accountingCompany') {
    const filteredAccountingCompanies = filterAccountingCompanies(dominoAuthorities)
    return getUniqueItems(filteredAccountingCompanies, 'accountingCompany')
  }
  const filteredAdministrations = filterAdministrations(dominoAuthorities)
  return getUniqueItems(filteredAdministrations, x => `${x.accountingCompany}${x.endCustomer}`)
}

const getAccountingCompanyNames = createSelector(getUser, user => {
  return user.accountingCompanyList.reduce((result, item) => ({
    ...result,
    [item.code]: item.name
  }), {})
})

const getAdministrationNames = createSelector(getUser, user => {
  return user.accountingCompanyList
    .map(accountingCompany => accountingCompany.endCustomerList.reduce((result, item) => ({
      ...result,
      [accountingCompany.code + item.code]: item.name
    }), {}))
    .reduce((result, item) => ({
      ...result,
      ...item
    }), {})
})

const getUserAccountingCompanies = createSelector(
  [getUserAuthorities, getAccountingCompanyNames],
  (authorities, names) => {
    const accountingCompanies = filterAuthorities(authorities, 'accountingCompany')
    return accountingCompanies.map(x => ({
      id: x.accountingCompany,
      name: names[x.accountingCompany] || x.accountingCompany
    })).sort((a, b) => a.name.localeCompare(b.name, 'fi', { numeric: true }))
  }
)

const getUserAdministrations = createSelector(
  [getUserAuthorities, getAdministrationNames],
  (authorities, names) => {
    const administrations = filterAuthorities(authorities)
    return administrations.map(x => ({
      id: `${x.accountingCompany}${x.endCustomer}`,
      name: names[`${x.accountingCompany}${x.endCustomer}`] || `${x.accountingCompany}/${x.endCustomer}`
    })).sort((a, b) => a.name.localeCompare(b.name, 'fi', { numeric: true }))
  }
)

export const getCompanyRedirectUrls = createSelector(getUser, user => {
  return user.accountingCompanyList.reduce((result, item) => ({
    ...result,
    ...getAdministrationRedirectUrls(item.endCustomerList, item.code)
  }), {})
})

export const getAutoRedirectUrlForAdministration = createSelector(
  [getUserAdministrations, getUserAccountingCompanies, getCompanyRedirectUrls],
  (userAdministrations, userAccountingCompanies, redirectUrls) => {
    const shouldRedirect = (userAdministrations.length === 1 && userAccountingCompanies.length === 0)
    return shouldRedirect
      ? redirectUrls[`${userAdministrations[0].id}`]
      : ''
  })

const getAdministrationRedirectUrls = (companies, accountingCompanyCode) =>
  companies.reduce((result, item) => {
    const mainApp = item.applicationList.find(x => x.path.includes('main.nsf'))
    return {
      ...result,
      [accountingCompanyCode + item.code]: mainApp
        ? `https://${mainApp.serverName}/${mainApp.path.replace(/\\/g, '/')}?OpenDatabase`
        : ''
    }
  }, {})

export const getUserCompanyList = createSelector(
  [getUserAccountingCompanies, getUserAdministrations, getCompanyRedirectUrls, getUser],
  (accountingCompanies, administrations, redirectUrls, user) => [
    ...accountingCompanies.map(x => ({
      ...x,
      type: 'accountingCompany',
      url: getAccountingCompanyRedirectUrl(x.id, user.accountingCompanyList)
    })),
    ...administrations.map(x => ({
      ...x,
      type: 'administration',
      url: redirectUrls[x.id]
    }))
  ]
)
