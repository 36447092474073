import { createApiClient } from '@heeros/client-sdk'
import { push } from 'connected-react-router'

import { getServerFromQuery, getServerFromRedirectUrl, getMfaUsername, getMfaSession, getMfaChallenge } from '../containers/selectors/qsp-selectors'
import { createReducer } from '../store/create-reducer'
import { setIsUnauthorized } from './app'
import { handleAuthenticatedUser, fetchCurrentUser } from './user'
import { setSession } from '../utils/cognito'

const loginApi = createApiClient('login-v1')

const SET_SUBMIT_STATUS = 'SET_SUBMIT_STATUS'
const SET_REDIRECT_URL = 'SET_REDIRECT_URL'
const SET_LOGIN_SERVER = 'SET_LOGIN_SERVER'
const SET_RESET_COMPLETED = 'SET_RESET_COMPLETED'
const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'
const SET_PASSWORD_RESET_REQUESTED = 'SET_PASSWORD_RESET_REQUESTED'
const SET_MFA_CHALLENGE = 'SET_MFA_CHALLENGE'

const setSubmitStatus = isSubmitting => ({
  type: SET_SUBMIT_STATUS,
  payload: { isSubmitting }
})

export const setRedirectUrl = url => ({
  type: SET_REDIRECT_URL,
  payload: { url }
})

export const setLoginServer = loginServer => ({
  type: SET_LOGIN_SERVER,
  payload: { loginServer }
})

export const setResetCompleted = resetCompleted => ({
  type: SET_RESET_COMPLETED,
  payload: { resetCompleted }
})

export const setLoginErrorState = hasError => ({
  type: SET_LOGIN_ERROR,
  payload: { hasError }
})

const setPasswordResetRequested = passwordResetRequested => ({
  type: SET_PASSWORD_RESET_REQUESTED,
  payload: { passwordResetRequested }
})

const setMfaChallenge = MfaChallenge => ({
  type: SET_MFA_CHALLENGE,
  payload: MfaChallenge
})

const handleSuccessfulLogin = (auth) => async (dispatch, getState) => {
  const { login } = getState()

  setSession(auth)

  if (login.redirectUrl) {
    window.location.href = login.redirectUrl
    return
  }
  dispatch(setIsUnauthorized(false))
  dispatch(setLoginErrorState(false))

  if (!login.redirectUrl) {
    await dispatch(fetchCurrentUser())
  }

  dispatch(handleAuthenticatedUser())
}

export const handleLoginSubmit = ({ username, password }) => async (dispatch, getState) => {
  if (!username || !password) return

  dispatch(setPasswordResetRequested(false))

  const server = getServerFromQuery(getState()) || getServerFromRedirectUrl(getState())

  const apiPath = server
    ? `/?server=${server}`
    : '/'

  dispatch(setSubmitStatus(true))

  try {
    const response = await loginApi.post(apiPath, JSON.stringify({ username, password }))
    if (response?.challenge === 'SOFTWARE_TOKEN_MFA') {
      dispatch(setMfaChallenge(response))
      dispatch(setLoginErrorState(false))
      dispatch(push('/mfa-required'))
    } else {
      await dispatch(handleSuccessfulLogin(response))
    }
  } catch (error) {
    if (error.code === 401) {
      dispatch(setLoginErrorState('TooManyAttemptsError'))
    } else {
      dispatch(setLoginErrorState('InvalidCredentialsError'))
    }
  }

  dispatch(setSubmitStatus(false))
}
export const handleMfaSubmit = ({ usercode }) => async (dispatch, getState) => {
  dispatch(setSubmitStatus(true))

  try {
    const username = getMfaUsername(getState())
    const session = getMfaSession(getState())
    const challenge = getMfaChallenge(getState())
    const response = await loginApi.post(`/mfa/${challenge}`, JSON.stringify({ username, session, usercode }))
    await dispatch(handleSuccessfulLogin(response))
  } catch (error) {
    dispatch(setLoginErrorState(true))
  }
  dispatch(setSubmitStatus(false))
}

export const handleResetPasswordRequestSubmit = (username) => async (dispatch, getState) => {
  if (!username) return

  dispatch(setSubmitStatus(true))

  try {
    await loginApi.post('/recoveries', JSON.stringify({ username }))
    dispatch(setPasswordResetRequested({ state: true, message: 'loginView.passwordResetEmailSent' }))
  } catch (err) {
    dispatch(setPasswordResetRequested({ state: true, message: 'loginView.passwordResetEmailError', severity: 'error' }))
  }

  dispatch(setSubmitStatus(false))
  dispatch(push('/'))
}

const initialState = {
  isSubmitting: false,
  passwordResetRequested: false,
  passwordResetMessage: '',
  passwordResetMessageSeverity: '',
  redirectUrl: '',
  loginServer: '',
  resetCompleted: false,
  hasError: false,
  mfaChallenge: '',
  mfaUsername: '',
  mfaSession: ''
}

const ACTION_HANDLERS = {
  [SET_SUBMIT_STATUS]: (state, action) => ({
    ...state,
    isSubmitting: action.payload.isSubmitting
  }),
  [SET_REDIRECT_URL]: (state, action) => ({
    ...state,
    redirectUrl: action.payload.url
  }),
  [SET_LOGIN_SERVER]: (state, action) => ({
    ...state,
    loginServer: action.payload.loginServer
  }),
  [SET_RESET_COMPLETED]: (state, action) => ({
    ...state,
    resetCompleted: action.payload.resetCompleted
  }),
  [SET_LOGIN_ERROR]: (state, action) => ({
    ...state,
    hasError: action.payload.hasError
  }),
  [SET_PASSWORD_RESET_REQUESTED]: (state, action) => ({
    ...state,
    passwordResetRequested: action.payload.passwordResetRequested.state,
    passwordResetMessage: action.payload.passwordResetRequested.message,
    passwordResetMessageSeverity: action.payload.passwordResetRequested.severity || 'success'
  }),
  [SET_MFA_CHALLENGE]: (state, action) => ({
    ...state,
    mfaChallenge: action.payload.challenge,
    mfaUsername: action.payload.username,
    mfaSession: action.payload.session
  })
}

export default createReducer({
  handlers: ACTION_HANDLERS,
  initialState
})
