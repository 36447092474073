import React from 'react'
import PropTypes from 'prop-types'
import { RenderGate, ProgressOverlay } from '@heeros/react-sdk'

import ProgressIndicator from '../../containers/ProgressIndicatorContainer'

const AppContent = ({ routes, app }) => (
  <>
    <RenderGate shouldRender={app.isLoading}>
      <ProgressOverlay />
    </RenderGate>
    <RenderGate shouldRender={!app.isLoading}>
      {routes}
      <ProgressIndicator />
    </RenderGate>
  </>
)

AppContent.propTypes = {
  routes: PropTypes.node.isRequired,
  app: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired
  })
}

export default AppContent
