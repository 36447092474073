import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField, InputAdornment, Button, makeStyles, CircularProgress, Link, Typography } from '@material-ui/core'
import UsernameIcon from '@material-ui/icons/Person'
import PasswordIcon from '@material-ui/icons/Lock'
import { useTranslation } from 'react-i18next'
import { RenderGate } from '@heeros/react-sdk'

const useStyles = makeStyles(theme => ({
  formContainer: {
    paddingTop: theme.spacing(2)
  },
  submitContainer: {
    position: 'relative'
  },
  submitProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}))

const NoText = () => (
  <span>&nbsp;</span>
)

const LoginForm = props => {
  const { handleForgotPassword } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const [inputValues, setInputValues] = useState({ username: '', password: '' })

  const handleInputChange = key => event => setInputValues({ ...inputValues, [key]: event.target.value })

  const handleForgotPasswordClick = useCallback(() => {
    return handleForgotPassword(inputValues.username)
  }, [inputValues, handleForgotPassword])

  return (
    <Grid container direction='column' className={classes.formContainer} spacing={2}>
      <Grid item>
        <TextField
          data-test='username'
          name='username'
          autoComplete='username'
          value={inputValues.username}
          onChange={handleInputChange('username')}
          label={t('loginView.usernameLabel')}
          placeholder={t('loginView.usernameLabel')}
          fullWidth
          variant='outlined'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <UsernameIcon />
              </InputAdornment>
            )
          }}
          onKeyPress={event => {
            if (event.key === 'Enter') props.handleLoginSubmit(inputValues)
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          data-test='password'
          name='password'
          autoComplete='password'
          value={inputValues.password}
          onChange={handleInputChange('password')}
          label={t('loginView.passwordLabel')}
          placeholder={t('loginView.passwordLabel')}
          type='password'
          fullWidth
          variant='outlined'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <PasswordIcon />
              </InputAdornment>
            )
          }}
          onKeyPress={event => {
            if (event.key === 'Enter') props.handleLoginSubmit(inputValues)
          }}
        />
      </Grid>
      <Grid item className={classes.submitContainer}>
        <Button
          data-test='loginButton'
          variant='contained'
          color='primary'
          disabled={props.isSubmitting}
          fullWidth
          onClick={() => props.handleLoginSubmit(inputValues)}
        >
          {props.isSubmitting ? <NoText /> : t('loginView.loginButton')}
        </Button>
        <RenderGate shouldRender={props.isSubmitting}>
          <CircularProgress size={24} className={classes.submitProgress} />
        </RenderGate>
      </Grid>
      <Grid item>
        <Typography align='right'>
          <Link component='button' onClick={handleForgotPasswordClick} variant='body2'>
            {t('loginView.forgotPassword')}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  )
}

LoginForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  handleLoginSubmit: PropTypes.func.isRequired
}

export default LoginForm
