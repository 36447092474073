import { push } from 'connected-react-router'
import { createReducer } from '../store/create-reducer'
import { setLoginErrorState } from './login'

const SET_APP_LOADING_STATE = 'SET_APP_LOADING_STATE'
const SET_IS_UNAUTHORIZED = 'SET_IS_UNAUTHORIZED'
const SET_USERNAME = 'SET_USERNAME'

export const SET_LOCALE = 'SET_LOCALE'

export const setAppLoadingState = isLoading => ({
  type: SET_APP_LOADING_STATE,
  payload: { isLoading }
})

export const setLocale = locale => ({
  type: SET_LOCALE,
  payload: { locale }
})

export const setIsUnauthorized = (isUnauthorized) => ({
  type: SET_IS_UNAUTHORIZED,
  payload: { isUnauthorized }
})

const setUsername = (username) => ({
  type: SET_USERNAME,
  payload: { username }
})

export const handleForgotPassword = (username) =>
  (dispatch, getState) => {
    dispatch(setLoginErrorState(false))
    dispatch(setUsername(username))
    dispatch(push('/forgot-password'))
  }

const ACTION_HANDLERS = {
  [SET_APP_LOADING_STATE]: (state, action) => ({
    ...state,
    isLoading: action.payload.isLoading
  }),
  [SET_LOCALE]: (state, action) => ({
    ...state,
    locale: action.payload.locale
  }),
  [SET_IS_UNAUTHORIZED]: (state, action) => ({
    ...state,
    authenticatedUserUnauthorized: action.payload.isUnauthorized
  }),
  [SET_USERNAME]: (state, action) => ({
    ...state,
    username: action.payload.username
  })
}

const initialState = {
  isLoading: true,
  authenticatedUserUnauthorized: false,
  username: ''
}

export default createReducer({
  handlers: ACTION_HANDLERS,
  initialState
})
