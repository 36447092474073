import React from 'react'
import { Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

import { history } from './store'
import Route from './components/RouteWithLayout'

import LoginView from './containers/LoginViewContainer'
import CompanySelectionView from './containers/CompanySelectionViewContainer'
import ForgotPasswordView from './containers/ForgotPasswordViewContainer'
import ResetPasswordView from './containers/ResetPasswordViewContainer'
import MfaChallengeView from './containers/MfaChallengeViewContainer'

export const RouteName = {
  INDEX: '/',
  UNAUTHORIZED: '/unauthorized',
  MFAREQUIRED: '/mfa-required',
  AUTHENTICATED: '/authenticated',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password'
}

const AppRoutes = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route exact path={RouteName.INDEX} component={LoginView} />
      <Route exact path={RouteName.UNAUTHORIZED} component={LoginView} />
      <Route exact path={RouteName.MFAREQUIRED} component={MfaChallengeView} />
      <Route exact path={RouteName.AUTHENTICATED} component={CompanySelectionView} />
      <Route exact path={RouteName.FORGOT_PASSWORD} component={ForgotPasswordView} />
      <Route exact path={RouteName.RESET_PASSWORD} component={ResetPasswordView} />
    </Switch>
  </ConnectedRouter>
)

export default AppRoutes
