import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Typography, Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { RenderGate } from '@heeros/react-sdk'

import MfaForm from './MfaForm'
import AlertBox from './AlertBox'

const useStyles = makeStyles(theme => ({
  infoBoxContainer: {
    padding: theme.spacing(1.5, 0)
  },
  successAnimation: {
    transform: 'scale(1)',
    backfaceVisibility: 'hidden',
    animation: '$grow 0.2s cubic-bezier(.36,.07,.19,.97) both'
  },
  '@keyframes grow': {
    '0%': { transform: 'scale(1) rotate(0deg)' },
    '50%': { transform: 'scale(1.05) rotate(2deg)' },
    '100%': { transform: 'scale(1) rotate(0deg)' }
  },
  rootContainer: {
    minHeight: '300px'
  }
}))

const MfaChallengeView = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Grid container direction='column' className={classes.rootContainer}>
      <Typography variant='h4'>{t('MfaChallengeView.titleText')}</Typography>
      <Grid item className={classNames(classes.infoBoxContainer, classes.successAnimation)}>
        <AlertBox
          severity='info'
          elevation={1}
          message={t('MfaChallengeView.mfaCodeRquired')}
        />
      </Grid>
      <RenderGate shouldRender={props.hasError}>
        <Grid item className={classes.infoBoxContainer}>
          <AlertBox
            elevation={1}
            variant='filled'
            severity='error'
            message={t('MfaChallengeView.invalidCode')}
          />
        </Grid>
      </RenderGate>
      <MfaForm {...props} />
    </Grid>
  )
}

MfaChallengeView.propTypes = {
  hasError: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleLoginSubmit: PropTypes.func.isRequired,
  userUnauthorized: PropTypes.bool.isRequired
}

export default MfaChallengeView
