import React from 'react'
import { Alert } from '@material-ui/lab'
import { Grid } from '@material-ui/core'

const AlertBox = ({ severity, message, elevation, variant }) => {
  return (
    <Grid item>
      <Alert
        severity={severity}
        elevation={elevation}
        variant={variant}
      >
        {message}
      </Alert>
    </Grid>
  )
}

export default AlertBox
