import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { I18nextProvider } from 'react-i18next'
import { ThemeProvider } from '@material-ui/core'

import i18n from '../../i18n'
import theme from '../../theme'
import AppContent from './AppContent'
import ProgressIndicator from '../ProgressIndicator'

const App = ({ routes, app }) => (
  <ThemeProvider theme={theme}>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<ProgressIndicator isOpen />}>
        <AppContent routes={routes} app={app} />
      </Suspense>
    </I18nextProvider>
  </ThemeProvider>
)

App.propTypes = {
  routes: PropTypes.node.isRequired,
  app: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired
  })
}

export default App
