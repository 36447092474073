import React from 'react'
import PropTypes from 'prop-types'

import { ProgressOverlay, RenderGate } from '@heeros/react-sdk'

const ProgressIndicator = props => (
  <RenderGate shouldRender={props.isOpen}>
    <ProgressOverlay />
  </RenderGate>
)

ProgressIndicator.propTypes = {
  isOpen: PropTypes.bool.isRequired
}

export default ProgressIndicator
