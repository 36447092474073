import { userService } from '@heeros/client-sdk'
import { replace } from 'connected-react-router'

import { createReducer } from '../store/create-reducer'
import { setLocale } from './app'
import { getNormalizedBrowserLanguage } from '../utils/language'
import { getAutoRedirectUrlForAdministration } from '../containers/selectors/user-selectors'

import { Auth } from '@aws-amplify/auth'

const RECEIVE_USER_DATA = 'RECEIVE_USER_DATA'

const receiveUser = data => ({
  type: RECEIVE_USER_DATA,
  payload: { data }
})

export const fetchCurrentUser = () => async dispatch => {
  const user = await userService.getCurrentUser()
  const locale = user.locale.replace(/_/g, '-')
  dispatch(receiveUser({
    ...user,
    locale
  }))

  return user
}

export const setLocaleFromBrowser = () => (dispatch, getState) => {
  const locale = getNormalizedBrowserLanguage()
  dispatch(setLocale(locale))
}

export const handleUnauthenticatedUser = () => (dispatch, getState) => {
  dispatch(setLocaleFromBrowser())
  dispatch(replace('/'))
  Auth.signOut()
}

export const handleAuthenticatedUser = () => (dispatch, getState) => {
  const state = getState()
  const { user, app } = state
  dispatch(setLocale(user.locale))

  if (app.authenticatedUserUnauthorized) return

  dispatch(replace('/authenticated'))

  const automaticRedirectUrl = getAutoRedirectUrlForAdministration(state)

  const redirectUrl = automaticRedirectUrl

  if (redirectUrl) {
    window.location.href = redirectUrl
  }
}

const initialState = {
  username: '',
  email: '',
  authorities: [],
  accountingCompanyList: []
}

const ACTION_HANDLERS = {
  [RECEIVE_USER_DATA]: (state, action) => ({
    ...state,
    ...action.payload.data
  })
}

export default createReducer({
  handlers: ACTION_HANDLERS,
  initialState
})
