import { connect } from 'react-redux'

import ProgressIndicator from '../components/ProgressIndicator'

const mapStateToProps = state => ({
  ...state.progressIndicator
})

const mapDispatchToProps = {}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  ...dispatchProps
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ProgressIndicator)
