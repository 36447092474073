import { connect } from 'react-redux'

import ForgotPasswordView from '../components/ForgotPassword'

import { handleResetPasswordRequestSubmit } from '../modules/login'

const mapStateToProps = state => ({
  username: state.app.username,
  isSubmitting: state.login.isSubmitting
})

const mapDispatchToProps = {
  handleResetPasswordSubmit: handleResetPasswordRequestSubmit
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordView)
