import { SET_LOCALE } from '../modules/app'
import i18n from '../i18n'

export const i18nMiddleware = store => next => action => {
  if (action.type !== SET_LOCALE) return next(action)

  i18n.changeLanguage(action.payload.locale)

  return next(action)
}
