import { connect } from 'react-redux'

import ResetPasswordView from '../components/ResetPassword'

import { handlePasswordResetSubmit } from '../modules/reset'

const mapStateToProps = state => ({
  isSubmitting: state.reset.isSubmitting,
  errorMessage: state.reset.errorMessage
})

const mapDispatchToProps = {
  handlePasswordResetSubmit
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordView)
