import { RenderGate } from '@heeros/react-sdk'
import { Button, CircularProgress, Divider, Grid, Grow, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/CheckCircle'
import PasswordIcon from '@material-ui/icons/Lock'
import classNames from 'classnames'
import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CodeInput from 'react-verification-code-input'
import AlertBox from '../LoginView/AlertBox'
import PasswordInfoBox from './PasswordInfoBox'
import { useValidator } from './validator'

const CODE_LENGTH = 6
const RESET_CODE_PLACEHOLDER = ['_', '_', '_', '_', '_', '_']

const useStyles = makeStyles(theme => ({
  formContainer: {
    paddingTop: `${theme.spacing(2)}px !important`
  },
  submitContainer: {
    marginTop: theme.spacing(2),
    position: 'relative'
  },
  submitProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  rootContainer: {
    minHeight: '300px'
  },
  infoBoxContainer: {
    padding: theme.spacing(1.5, 0)
  },
  codeEntry: {
    width: '100% !important',
    '& > p': {
      ...theme.typography.body1,
      color: theme.palette.text.hint
    },
    '& input': {
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      height: '56px !important',
      backgroundColor: '#f2f3f7',
      borderColor: '#bababe !important',
      width: `calc(100% / ${CODE_LENGTH}) !important`
    },
    '& input:focus': {
      borderColor: `${theme.palette.primary.main} !important`,
      borderWidth: '2px',
      zIndex: 100
    },
    '& input::placeholder': {
      color: theme.palette.text.hint
    },
    '& input:first-of-type': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius
    },
    '& input:last-of-type': {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius
    }
  },
  checkIconContainer: {
    height: 0,
    width: '100%',
    textAlign: 'right'
  },
  checkIcon: {
    color: theme.palette.primary.light,
    transform: 'rotate(90deg)'
  },
  applyAnimation: {
    animation: '$rotate 0.15s ease-out both'
  },
  '@keyframes rotate': {
    '0%': { transform: 'rotate(90deg)' },
    '100%': { transform: 'rotate(0deg)' }
  },
  successAnimation: {
    transform: 'scale(1)',
    backfaceVisibility: 'hidden',
    animation: '$grow 0.2s cubic-bezier(.36,.07,.19,.97) both'
  },
  '@keyframes grow': {
    '0%': { transform: 'scale(1) rotate(0deg)' },
    '50%': { transform: 'scale(1.05) rotate(2deg)' },
    '100%': { transform: 'scale(1) rotate(0deg)' }
  }
}))

const NoText = () => <span>&nbsp;</span>

const ResetPasswordView = (props) => {
  const { isSubmitting, errorMessage, handlePasswordResetSubmit } = props

  const classes = useStyles()
  const { t } = useTranslation()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [resetCode, setResetCode] = useState('')
  const [resetCodeEntered, setResetCodeEntered] = useState(false)
  const [validationErrors, hasErrors] = useValidator(password, confirmPassword)

  const passwordInputRef = useRef()

  const handleResetCodeChange = useCallback(values => {
    setResetCode(values)
    if (values.length === CODE_LENGTH) {
      setResetCodeEntered(true)
      if (passwordInputRef.current) {
        passwordInputRef.current.focus()
      }
    } else {
      setResetCodeEntered(false)
    }
  }, [setResetCode, setResetCodeEntered])

  const handleSubmit = useCallback(() => {
    if (!hasErrors) {
      handlePasswordResetSubmit(resetCode, password)
    }
  }, [hasErrors, handlePasswordResetSubmit, resetCode, password])

  return (
    <Grid container direction='column' className={classes.rootContainer}>
      <RenderGate shouldRender={Boolean(errorMessage)}>
        <Grid item className={classNames(classes.infoBoxContainer, classes.successAnimation)}>
          <AlertBox
            severity='error'
            elevation={1}
            message={t(errorMessage)}
          />
        </Grid>
      </RenderGate>
      <Typography variant='h4'>{t('resetPasswordView.titleText')}</Typography>
      <form name='reset-form'>
        <Grid item container direction='column' spacing={2} className={classes.formContainer}>
          <Grid item>
            <div className={classes.checkIconContainer}>
              <Grow in={resetCodeEntered}>
                <CheckIcon
                  className={classNames(classes.checkIcon, resetCodeEntered && classes.applyAnimation)}
                />
              </Grow>
            </div>
            <CodeInput
              type='text'
              autoFocus
              fields={CODE_LENGTH}
              onChange={handleResetCodeChange}
              title={t('resetPasswordView.resetCodeLabel')}
              placeholder={RESET_CODE_PLACEHOLDER}
              className={classes.codeEntry}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <TextField
              data-test='reset-password'
              name='reset-password'
              id='reset-password-input'
              autoComplete='password'
              type='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
              label={t('resetPasswordView.passwordLabel')}
              placeholder={t('resetPasswordView.passwordLabel')}
              fullWidth
              variant='outlined'
              inputRef={passwordInputRef}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <PasswordIcon />
                  </InputAdornment>
                )
              }}
              onKeyPress={event => {
                if (event.key === 'Enter') handleSubmit()
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              data-test='reset-password-confirm'
              name='reset-password-confirm'
              id='reset-password-confirm-input'
              autoComplete='password'
              type='password'
              value={confirmPassword}
              error={confirmPassword !== '' && confirmPassword !== password}
              onChange={e => setConfirmPassword(e.target.value)}
              label={t('resetPasswordView.confirmPasswordLabel')}
              placeholder={t('resetPasswordView.confirmPasswordLabel')}
              fullWidth
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <PasswordIcon />
                  </InputAdornment>
                )
              }}
              onKeyPress={event => {
                if (event.key === 'Enter') handleSubmit()
              }}
            />
          </Grid>
          <Grid item className={classes.submitContainer}>
            <Button
              data-test='reset-button'
              variant='contained'
              color='primary'
              disabled={isSubmitting || hasErrors}
              fullWidth
              onClick={handleSubmit}
            >
              {isSubmitting ? <NoText /> : t('resetPasswordView.submitButton')}
            </Button>
            <RenderGate shouldRender={isSubmitting}>
              <CircularProgress size={24} className={classes.submitProgress} />
            </RenderGate>
          </Grid>
        </Grid>
      </form>
      <PasswordInfoBox errors={validationErrors} />
    </Grid>
  )
}

export default ResetPasswordView
