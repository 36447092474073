const getEnvironmentFromHostname = () => {
  const host = window.location.hostname
  if (host.includes('cloud')) return 'cloud'
  if (host.includes('pilot')) return 'pilot'
  if (host.includes('test')) return 'test'

  return 'dev'
}

const env = getEnvironmentFromHostname()
const apiRootUrl = `https://api.${env}.heeros.com`

export const appSettings = {
  // API
  somethingApi: `${apiRootUrl}/my-service-v1/something`,

  // Misc
  env: getEnvironmentFromHostname()
}

export const getAccountingCompanyRedirectUrl = (accountingCompanyCode, accountingCompanyList = []) => {
  const accCompany = accountingCompanyList.find(acc => acc.code === accountingCompanyCode)
  if (!accCompany || !accCompany.host) {
    return `https://${getEnvironmentFromHostname()}.heeros.com/menudashboard/index.html#/${accountingCompanyCode}`
  }
  return `https://${accCompany.host}.heeros.com/Asiakas/${accountingCompanyCode}/menu.nsf`
}
