import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'react-redux'
import createStore from './store'
import App from './containers/AppContainer'
import './utils/polyfills'

const MOUNT_NODE = document.getElementById('root')

const render = store => {
  // routes must be imported here to get the hotmodule-replacement to work
  const routes = require('./routes').default()
  ReactDOM.render(
    <Provider store={store}>
      <App routes={routes} />
    </Provider>,
    MOUNT_NODE
  )
}

const init = async () => {
  const store = createStore()
  if (module.hot) {
    module.hot.accept('./routes', () => {
      ReactDOM.unmountComponentAtNode(MOUNT_NODE)
      render(store)
    })
  }

  render(store)
}

init()
