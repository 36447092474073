import { connect } from 'react-redux'
import LoginView from '../components/LoginView'

import { handleLoginSubmit } from '../modules/login'
import { handleForgotPassword } from '../modules/app'
import { getUserUnauthorized } from './selectors/user-selectors'
import { getPasswordResetCompleted } from './selectors/qsp-selectors'

const mapStateToProps = state => ({
  ...state.login,
  userUnauthorized: getUserUnauthorized(state),
  passwordResetCompleted: getPasswordResetCompleted(state)
})

const mapDispatchToProps = {
  handleLoginSubmit,
  handleForgotPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginView)
