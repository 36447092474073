import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Typography, Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { RenderGate } from '@heeros/react-sdk'

import LoginForm from './LoginForm'
import AlertBox from './AlertBox'

const useStyles = makeStyles(theme => ({
  infoBoxContainer: {
    padding: theme.spacing(1.5, 0)
  },
  successAnimation: {
    transform: 'scale(1)',
    backfaceVisibility: 'hidden',
    animation: '$grow 0.2s cubic-bezier(.36,.07,.19,.97) both'
  },
  '@keyframes grow': {
    '0%': { transform: 'scale(1) rotate(0deg)' },
    '50%': { transform: 'scale(1.05) rotate(2deg)' },
    '100%': { transform: 'scale(1) rotate(0deg)' }
  },
  rootContainer: {
    minHeight: '300px'
  }
}))

const LoginView = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Grid container direction='column' className={classes.rootContainer}>
      <Typography variant='h4'>{t('loginView.titleText')}</Typography>
      <RenderGate shouldRender={props.passwordResetCompleted}>
        <Grid item className={classNames(classes.infoBoxContainer, classes.successAnimation)}>
          <AlertBox
            severity='success'
            elevation={1}
            message={t('loginView.passwordSuccessfullyReset')}
          />
        </Grid>
      </RenderGate>
      <RenderGate shouldRender={props.passwordResetRequested}>
        <Grid item className={classNames(classes.infoBoxContainer, classes.successAnimation)}>
          <AlertBox
            severity={props.passwordResetMessageSeverity}
            elevation={1}
            message={t(props.passwordResetMessage)}
          />
        </Grid>
      </RenderGate>
      <RenderGate shouldRender={props.userUnauthorized}>
        <Grid item className={classes.infoBoxContainer}>
          <AlertBox
            severity='warning'
            message={t('loginView.unauthorizedUser')}
          />
        </Grid>
      </RenderGate>
      <RenderGate shouldRender={props.hasError}>
        <Grid item className={classes.infoBoxContainer}>
          <AlertBox
            elevation={1}
            variant='filled'
            severity='error'
            message={props.hasError === 'TooManyAttemptsError' ? t('loginView.tooManyAttempts') : t('loginView.invalidCredentials')}
          />
        </Grid>
      </RenderGate>
      <LoginForm {...props} />
    </Grid>
  )
}

LoginView.propTypes = {
  hasError: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleLoginSubmit: PropTypes.func.isRequired,
  userUnauthorized: PropTypes.bool.isRequired
}

export default LoginView
